<template>
  <main class="o-flex--align-center o-flex--justify-center c-login-page c-wrapper--min-100vh">
    <h2 class="c-logo c-logo__contained"><span class="c-logo__text">ScoreExpress</span></h2>

    <form class="c-card c-card--login" v-on:submit="login($event)">
      <div class="o-layout o-flex--justify-center">
        <div class="o-layout__item u-1-of-1 u-2-of-3-at-small u-margin-top-medium">
          <slot name="feedback"></slot>
        </div>
        <div class="o-layout__item u-1-of-1 u-2-of-3-at-small u-margin-top-medium">
          <div class="o-layout">

            <div class="o-layout__item">
              <div v-if="type === 'main' && isLocal">
                <label class="c-input__label">{{this.localUser.name}}</label>
              </div>
              <sceTextField v-else v-model="username" name="username" label="username"
                                v-validate data-vv-rules="required"
                                :class="{'is-invalid': errors.has('username')}" />
            </div>
            <div class="o-layout__item">
              <scePasswordField v-model="password" name="password" label="password"
                                v-validate data-vv-rules="required"
                                :class="{'is-invalid': errors.has('password')}" />
            </div>
          </div>
        </div>

        <div class="o-layout__item u-1-of-1 u-2-of-3-at-small u-margin-top-medium u-margin-bottom-small">
          <div class="o-layout o-flex o-flex--justify-end">
            <div class="o-layout__item u-1-of-1 u-1-of-2-at-small">
              <button v-on:click="login($event)"
                      class="c-button c-button--large c-button--primary u-1-of-1">
                {{$t('login')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
  export default {
    name: "login",
    props: ['type'],
    data: function () {
      return {
        username: undefined,
        password: undefined
      }
    },
    computed: {
      isLocal: function() {
        return this.$store.state.environment.local;
      },
      localUser: function() {
        return this.$store.state.environment.localUser;
      }
    },
    created: function() {
      if (this.type === 'main' && this.isLocal) {
        this.username = this.localUser.username;
      }
    },
    methods: {
      login: function (event) {
        this.$validator.validateAll().then(() => {
          if (! this.errors.any()) {
            this.$emit('login', {
              credentials: {
                username: this.username,
                password: this.password,
              }
            });
          }
          event.preventDefault();
        });
      }
    }
  };

</script>

<style scoped>

</style>
