'use strict';

import Vue from 'vue';
import moment from 'moment';

const httpAL = {
  'nl-BE': 'nl-BE, nl; 0.9, en; 0.8',
  'nl': 'nl, en; 0.8',
  'fr-BE': 'fr-BE, fr; 0.9, en; 0.8',
  'fr': 'fr, en; 0.8',
  'de': 'de, en; 0.8',
  'en': 'en',
}

export default {
  setLocale: function(locale) {
    console.log('set locale', locale);
    Vue.i18n.locale = locale;
    moment.locale(locale);
    Vue.http.headers.common['Accept-Language'] =httpAL[locale];
  },

  getMonths: function() {
    return [
      Vue.i18n.t('month.jan'),
      Vue.i18n.t('month.feb'),
      Vue.i18n.t('month.mar'),
      Vue.i18n.t('month.apr'),
      Vue.i18n.t('month.may'),
      Vue.i18n.t('month.jun'),
      Vue.i18n.t('month.jul'),
      Vue.i18n.t('month.aug'),
      Vue.i18n.t('month.sep'),
      Vue.i18n.t('month.oct'),
      Vue.i18n.t('month.nov'),
      Vue.i18n.t('month.dec'),
    ]
  },
  getDays: function() {
    return [
      Vue.i18n.t('day.mo'),
      Vue.i18n.t('day.tu'),
      Vue.i18n.t('day.we'),
      Vue.i18n.t('day.th'),
      Vue.i18n.t('day.fr'),
      Vue.i18n.t('day.sa'),
      Vue.i18n.t('day.su'),
    ]
  }
};
