
export default {
  path: 'customers',
  component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/customers.vue'),
  children: [{
    path: '/',
    name: 'admin.customers',
    component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/users.vue'),
  }, {
    path: ':userId',
    component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/customer.vue'),
    children: [{
      path: '/',
      name: 'admin.customer',
      component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/profile.vue'),
    }]
  }, {
    path: 'add',
    name: 'admin.customer.add',
    component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/addCustomer.vue'),
  }],
};
