
import store from 'client/store';
const filter = require("lodash/filter");
const find = require("lodash/find");
import auth from 'client/auth';
import Vue from 'vue';

export default [
  {
    path: '/judge/:eventId',
    component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/wrapper.vue'),
    beforeEnter: function (to, from, next) {
        auth.check().then(function(){
            store.dispatch("events.getById", {
                eventId: to.params.eventId
            }).then(() => {
              const event = store.state.events.current
              if (event.language) {
                Vue.i18n.locale = event.language;
              }
              const query = { eventId: to.params.eventId };
              Promise.all([
                store.dispatch("disciplines.get"),
                store.dispatch("eventDisciplines.get", query),
                store.dispatch("sessions.getByEvent", query),
                store.dispatch("panels.getByEvent", query),
                store.dispatch("members.get", query),
                store.dispatch("groups.get", query),
                store.dispatch("clubs.get", query)
              ]).then(() => {
                next();
              });
            });
        }).catch(function () {
            next({
                path: '/login'
            });
        })
    },
    children: [{
      path: '/',
      name: 'judge.panels',
      component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/panels.vue'),
    }, {
      path: 'panel/:panelId',
      component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/panel/wrapper.vue'),
      beforeEnter: function (to, from, next) {
        const panel = filter(store.state.panels.items, item => { return item.id === to.params.panelId })[0];
        const query =  { eventDisciplineId: panel.eventDisciplineId };

        Promise.all([
          store.dispatch("participations.get", query),
          store.dispatch("eventDiscipline.get", query),
          store.dispatch("categories.get", query),
        ]).then(next);
      },
      children: [{
        path: '/',
        name: 'judge.panel.members',
        component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/panel/members.vue'),
      }, {
        path: 'add/:position',
        name: 'judge.panel.member.add',
        component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/panel/member-add.vue'),
        meta: { hideHeader: true },
      }, {
        path: 'confirm/:panelMemberId',
        name: 'judge.panel.member.confirm',
        component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/panel/member-confirm.vue'),
        meta: { hideHeader: true },
      }, {
        path: 'judge/:panelMemberId',
        component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/judge/wrapper.vue'),
        children: [{
          path: '/',
          name: 'judge.judge.idle',
          component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/judge/idle.vue'),
        }, {
          path: 'edit/:exerciseId/:pass',
          name: 'judge.judge.exercise',
          component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/judge/exercise.vue'),
          meta: { hideHeader: true },
          beforeEnter: function(to, from, next) {
            store.dispatch('exercise.load', {
              panelId: to.params.panelId,
              panelMemberId: to.params.panelMemberId,
              exerciseId: to.params.exerciseId,
              pass: parseInt(to.params.pass)
            }).then(() => { next() });
          }
        }]
      }, {
        path: 'chair/:panelMemberId',
        component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/chair/wrapper.vue'),
        children: [{
          path: '/',
          name: 'judge.chair.rotations',
          component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/chair/rotations.vue'),
        },{
          path: 'block',
          component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/chair/blockWrapper.vue'),
          beforeEnter: function (to, from, next) {
            store.dispatch("panel.fetchBlock", {panelId: to.params.panelId }).then(next);
          },
          children: [{
            path: '/',
            name: 'judge.chair.block',
            component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/chair/block.vue'),
          },{
            path: 'exercise/:exerciseId/:pass',
            name: 'judge.chair.exercise',
            component: () => import(/* webpackChunkName: "judge" */ ('client/judge/components/judge/exercise.vue')),
            meta: { hideHeader: true },
            beforeEnter: function(to, from, next) {
              store.dispatch('exercise.load', {
                panelId: to.params.panelId,
                panelMemberId: to.params.panelMemberId,
                exerciseId: to.params.exerciseId,
                pass: parseInt(to.params.pass)
              }).then(() => { next() });
            },
          }]
        }]
      }]
    }, {
      path: 'counters/:sessionId',
      name: 'judge.counters',
      component: () => import(/* webpackChunkName: "judge" */ 'client/judge/components/counters/counters.vue'),
      beforeEnter: function(to, from, next) {
        const session = find(store.state.sessions.items, item => { return item.id === to.params.sessionId });
        const query = { eventDisciplineId: session.eventDisciplineId };
        Promise.all([
          store.dispatch("participations.get", query),
          store.dispatch('exercises.getSession', {sessionId: to.params.sessionId }),
        ]).then(next);
      }
    }]
  }
];
