export default {
  path: 'clubs',
  component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/wrapper.vue'),
  children: [{
    path: '/',
    name: 'admin.event.clubs',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/clubList.vue'),
  }, {
    path: 'add',
    name: 'admin.event.clubs.add',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/clubAdd.vue'),
  }, {
    path: ':clubId',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/clubWrapper.vue'),
    children: [{
      path: '/',
      name: 'admin.event.clubs.view',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/clubView.vue'),
    },{
      path: 'members',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/memberWrapper.vue'),
      children: [{
        path: '/',
        name: 'admin.event.clubs.members',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/memberList.vue'),
      },{
        path: ':memberId',
        name: 'admin.event.clubs.members.view',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/memberView.vue'),
      },{
        path: 'add',
        name: 'admin.event.clubs.members.add',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/memberAdd.vue'),
      }],
    }, {
      path: 'groups',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/groupWrapper.vue'),
      children: [{
        path: '/',
        name: 'admin.event.clubs.groups',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/groupList.vue'),
      }, {
        path: ':groupId',
        name: 'admin.event.clubs.groups.view',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/groupView.vue'),
      }, {
        path: 'add',
        name: 'admin.event.clubs.groups.add',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/groupAdd.vue'),
      }],
    },{
      path: 'teams',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/teamWrapper.vue'),
      children: [{
        path: '/',
        name: 'admin.event.clubs.teams',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/teamList.vue'),
      },{
        path: ':teamId',
        name: 'admin.event.clubs.teams.view',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/teamView.vue'),
      },{
        path: 'add',
        name: 'admin.event.clubs.teams.add',
        component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/clubs/teamAdd.vue'),
      }],
    }],
  }],
};
