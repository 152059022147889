import store from 'client/store';

export default {
  path: 'sessions',
  component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/wrapper.vue'),
  children: [{
    path: '/',
    name: 'admin.event.discipline.sessions',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/planningWrapper.vue'),
  }, {
    path: 'config',
    name: 'admin.event.discipline.sessions.planning.config',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/planningConfig.vue'),
  }, {
    path: 'bib',
    name: 'admin.event.discipline.sessions.bib',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/bibAssignment.vue'),
  }, {
    path: 'createSubdivisions',
    name: 'admin.event.discipline.sessions.createSubdivisions',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/createSubdivisions.vue'),
  }, {
    path: 'createOrder',
    name: 'admin.event.discipline.sessions.createOrder',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/createOrder.vue'),
  }, {
    path: 'checkOrder',
    name: 'admin.event.discipline.sessions.checkOrder',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/checkOrder.vue'),
  }, {
    path: 'add',
    name: 'admin.event.discipline.sessions.add',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/add.vue'),
  }, {
    path: ':sessionId',
    name: 'admin.event.discipline.sessions.view',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/view.vue'),
  },{
    path: ':sessionId/order',
    name: 'admin.event.discipline.sessions.order',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/order.vue'),
    children: [{
      path: ':set',
      name: 'admin.event.discipline.sessions.order.set',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/orderSet.vue'),
    },{
      path: 'mixed',
      name: 'admin.event.discipline.sessions.order.mixed',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/orderMixed.vue'),
    },{
      path: 'block/:blockId',
      name: 'admin.event.discipline.sessions.order.rotations',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/sessions/orderPerRotation.vue'),
    }],
  }, {
    path: ':sessionId/panels',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/panels/wrapper.vue'),
    beforeEnter: function (to, from, next) {
      store.dispatch('panels.getBySession', { sessionId: to.params.sessionId }).then(next)
    },
    children: [{
      path: '/',
      name: 'admin.event.discipline.sessions.panels',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/panels/sessionPanels.vue'),
    }, {
      path: ':panelId',
      name: 'admin.event.discipline.sessions.panel',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/panels/panel.vue'),
    }],
  }],
};
