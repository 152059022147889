<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <vue-progress-bar></vue-progress-bar>
    <div class="c-wrapper--min-100vh">
      <header class="c-header o-wrapper o-wrapper--huge js-header">
        <router-link :to="{ name: 'home'}" class="c-logo__link">
          <div class="c-logo"></div>
        </router-link>
        <h1 class="c-header__title u-push-1-of-5-at-medium u-3-of-4-at-medium"
            :title="$t('import.userSync.title')">{{$t('import.userSync.title')}}</h1>
      </header>
      <div class="o-layout o-layout--main u-padding-top-medium">
        <div class="o-layout__item">
          <h2 class="c-title c-title--page">{{user.name}}</h2>
          <p>{{user.username}}</p>
          <p>{{user.email}}</p>
        </div>
        <div class="o-layout__item u-margin-top-medium">
          <template v-if="status === 'idle'">
            <button class="c-button c-button--primary c-button--large" @click="submit()">{{$t('start import')}}</button>
            <router-link class="c-button c-button--secondary c-button--large" :to="{ name: 'importer.login'}">
              {{$t('cancel')}}
            </router-link>
          </template>
          <template v-else-if="status !== 'finished'">
            <h3 class="c-title c-title--page-section">
              {{status === 'sync' ? $t('import.syncing') : $t('import.checking')}}
            </h3>
            <div class="sk-circle">
              <div class="sk-circle1 sk-child"></div>
              <div class="sk-circle2 sk-child"></div>
              <div class="sk-circle3 sk-child"></div>
              <div class="sk-circle4 sk-child"></div>
              <div class="sk-circle5 sk-child"></div>
              <div class="sk-circle6 sk-child"></div>
              <div class="sk-circle7 sk-child"></div>
              <div class="sk-circle8 sk-child"></div>
              <div class="sk-circle9 sk-child"></div>
              <div class="sk-circle10 sk-child"></div>
              <div class="sk-circle11 sk-child"></div>
              <div class="sk-circle12 sk-child"></div>
            </div>
          </template>
          <template v-else>
            <h3 class="c-title c-title--page-section">{{$t('import.successful')}}</h3>
            <router-link class="c-button c-button--primary c-button--large" :to="{ name: 'login'}">
              {{$t('import.backToLogin')}}
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "syncUser",
    data: () => {
      return {
        status: 'idle',
        cycles: 0,
      }
    },
    computed: {
      token: function() {
        return this.$store.state.importer.token;
      },
      user: function() {
        return this.$store.state.importer.user;
      },
    },
    methods: {
      sync: function() {
        if (this.cycles > 1) return;

        this.cycles += 1;
        this.status = 'sync';
        this.$store.dispatch("importer.user.start").then(() => {
          this.status = 'check';

          this.$store.dispatch('importer.user.check').then((result) => {
            if (result.body === 'success') {
              this.$store.dispatch("environment.get").then(() => {
                this.status = 'finished';
              });
            } else {
              this.sync();
            }
          });
        })
      },
      submit: function () {
        this.cycles = 0;
        this.sync();
      }
    }
  }
</script>

<style scoped>
  .sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }

  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }

  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }

  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }

  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }

  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }

  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }

  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
